const axios = require('axios')

// localhost
// const port = 8090
// const url_service = 'http://localhost'

// -- devweb --
// const port = 8088
// const url_service = 'http://devweb.dtcgps.com'

const port = 8090
const url_service = 'http://backhaul.dtc.co.th'

async function getService(serviceName, args) {
  try {
    var parameter = ''
    if (args != '' && args != null && args != undefined) {
      for (var arg in args) {
        if (parameter == '') {
          parameter += '?'
        } else {
          parameter += '&'
        }
        parameter += arg + '=' + args[arg]
      }
    }
    var full_url = url_service + ':' + port + '/' + serviceName + parameter
    // console.log(full_url)
    const resp = await axios.get(full_url)
    // console.log(resp.data);
    return resp.data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function postService(serviceName, parameter) {
  try {
    var full_url = url_service + ':' + port + '/' + serviceName
    const resp = await axios.post(full_url, parameter)
    // console.log(resp.data);
    return resp.data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function postServiceDevweb(serviceName, parameter) {
  try {
    var full_url = 'http://devweb.dtcgps.com' + ':' + '8088' + '/' + serviceName
    const resp = await axios.post(full_url, parameter)
    // console.log(resp.data);
    return resp.data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function postServiceBody(serviceName, boby) {
  try {
    const resp = await axios.post('http://localhost:' + port + '/' + serviceName, boby)
    return resp.data
  } catch (error) {
    console.error(error)
    return error
  }
}

export default {
  getService,
  postService,
  postServiceDevweb,
  postServiceBody,
}
