/* eslint-disable no-undef */
const Swal = require('sweetalert2')
exports.alertMessage = (title, html, icon) => {
  Swal.fire({
    title: title,
    html: html,
    icon: icon,
    timer: 1500,
    confirmButtonText: 'Ok',
    allowOutsideClick: true,
  })
}
exports.askAction = (title, html, icon) => {
  return new Promise(resolve => {
    Swal.fire({
      title: title,
      text: html,
      icon: icon,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: true,
      showCancelButton: true,
      showCloseButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        resolve('yes')
      } else if (result.isDenied) {
        resolve('no')
      }
    })
  })
}

exports.LoadMsg = (title, html, icon) => {
  Swal.fire({
    title: title,
    html: html,
    icon: icon,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading()
    },
  })
}
exports.CloseMsg = () => {
  Swal.close()
}

exports.getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

exports.formatPrice = value => {
  let val = (value / 1).toFixed(2).replace('.', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

exports.toHHMMSS = value => {
  var sec_num = parseInt(value, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60

  // if (hours < 10) {
  //   hours = '0' + hours
  // }
  // if (minutes < 10) {
  //   minutes = '0' + minutes
  // }
  // if (seconds < 10) {
  //   seconds = '0' + seconds
  // }

  var time_data = {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
  // return hours + ':' + minutes //+ ':' + seconds;
  return time_data
}

exports.Between2Date = (value1, value2) => {
  // หาจำนวนวันที่ห่างกันระหว่างสองวัน
  const date1 = new Date(value1)
  const date2 = new Date(value2)
  var diffTime = Math.abs(date2 - date1)
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (date1 > date2) {
    diffTime = -1 * diffTime
    diffDays = -1 * diffDays
  }
  const dateData = {
    diffTime: diffTime,
    diffDays: diffDays,
  }
  return dateData
}

exports.isNumeric = num => {
  return !isNaN(num)
}

exports.setLocalStorageExpiry = (key, value, ttl) => {
  const now = new Date()
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

exports.getLocalStoragExpiry = key => {
  const itemStr = localStorage.getItem(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

exports.findString = (text, filter) => {
  let txt = text.toLowerCase()
  let txt_search = filter.toLowerCase()
  let check_find_string = (txt.indexOf(txt_search) > -1)
  return check_find_string
}

exports.encode = (string, key) => {
  key = sha1(key)
  strLen = string.length
  keyLen = key.length
  j = 0
  hash = ''
  for (i = 0; i < strLen; i++) {
    ordStr = string.substr(i, 1)
    ordStr = ordStr.charCodeAt(0)
    if (j == keyLen) {
      j = 0
    }
    ordKey = key.substr(j, 1)
    ordKey = ordKey.charCodeAt(0)
    j++
    hash += reverseString(base_convert(dechex(ordStr + ordKey), 16, 36))
  }
  return hash
}

exports.decode = (string, key) => {
  key = sha1(key)
  strLen = string.length
  keyLen = key.length
  j = 0
  hash = ''
  for (i = 0; i < strLen; i += 2) {
    ordStr = hexdec(base_convert(reverseString(string.substr(i, 2)), 36, 16))
    if (j == keyLen) {
      j = 0
    }
    ordKey = key.substr(j, 1)
    ordKey = ordKey.charCodeAt(0)
    j++
    hash += String.fromCharCode(ordStr - ordKey)
  }
  return hash
}

function reverseString(s) {
  return s
    .split('')
    .reverse()
    .join('')
}

function dechex(number) {
  //  discuss at: https://locutus.io/php/dechex/
  // original by: Philippe Baumann
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // improved by: https://stackoverflow.com/questions/57803/how-to-convert-decimal-to-hex-in-javascript
  //    input by: pilus
  //   example 1: dechex(10)
  //   returns 1: 'a'
  //   example 2: dechex(47)
  //   returns 2: '2f'
  //   example 3: dechex(-1415723993)
  //   returns 3: 'ab9dc427'
  if (number < 0) {
    number = 0xffffffff + number + 1
  }
  return parseInt(number, 10).toString(16)
}

function hexdec(hexString) {
  //  discuss at: https://locutus.io/php/hexdec/
  // original by: Philippe Baumann
  //   example 1: hexdec('that')
  //   returns 1: 10
  //   example 2: hexdec('a0')
  //   returns 2: 160
  hexString = (hexString + '').replace(/[^a-f0-9]/gi, '')
  return parseInt(hexString, 16)
}

function base_convert(number, frombase, tobase) {
  // eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/base_convert/
  // original by: Philippe Baumann
  // improved by: Rafał Kukawski (https://blog.kukawski.pl)
  //   example 1: base_convert('A37334', 16, 2)
  //   returns 1: '101000110111001100110100'
  return parseInt(number + '', frombase | 0).toString(tobase | 0)
}

function sha1(msg) {
  function rotate_left(n, s) {
    var t4 = (n << s) | (n >>> (32 - s))
    return t4
  }
  // eslint-disable-next-line no-unused-vars
  function lsb_hex(val) {
    var str = ''
    var i
    var vh
    var vl
    for (i = 0; i <= 6; i += 2) {
      vh = (val >>> (i * 4 + 4)) & 0x0f
      vl = (val >>> (i * 4)) & 0x0f
      str += vh.toString(16) + vl.toString(16)
    }
    return str
  }
  function cvt_hex(val) {
    var str = ''
    var i
    var v
    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f
      str += v.toString(16)
    }
    return str
  }
  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n')
    var utftext = ''
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n)
      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }
    return utftext
  }
  var blockstart
  var i, j
  var W = new Array(80)
  var H0 = 0x67452301
  var H1 = 0xefcdab89
  var H2 = 0x98badcfe
  var H3 = 0x10325476
  var H4 = 0xc3d2e1f0
  var A, B, C, D, E
  var temp
  msg = Utf8Encode(msg)
  var msg_len = msg.length
  var word_array = new Array()
  for (i = 0; i < msg_len - 3; i += 4) {
    j = (msg.charCodeAt(i) << 24) | (msg.charCodeAt(i + 1) << 16) | (msg.charCodeAt(i + 2) << 8) | msg.charCodeAt(i + 3)
    word_array.push(j)
  }
  switch (msg_len % 4) {
  case 0:
    i = 0x080000000
    break
  case 1:
    i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000
    break
  case 2:
    i = (msg.charCodeAt(msg_len - 2) << 24) | (msg.charCodeAt(msg_len - 1) << 16) | 0x08000
    break
  case 3:
    i = (msg.charCodeAt(msg_len - 3) << 24) | (msg.charCodeAt(msg_len - 2) << 16) | (msg.charCodeAt(msg_len - 1) << 8) | 0x80
    break
  }
  word_array.push(i)
  while (word_array.length % 16 != 14) word_array.push(0)
  word_array.push(msg_len >>> 29)
  word_array.push((msg_len << 3) & 0x0ffffffff)
  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i]
    for (i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)
    A = H0
    B = H1
    C = H2
    D = H3
    E = H4
    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 40; i <= 59; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8f1bbcdc) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    H0 = (H0 + A) & 0x0ffffffff
    H1 = (H1 + B) & 0x0ffffffff
    H2 = (H2 + C) & 0x0ffffffff
    H3 = (H3 + D) & 0x0ffffffff
    H4 = (H4 + E) & 0x0ffffffff
  }
  temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4)

  return temp.toLowerCase()
}


